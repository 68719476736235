<template>
    <div class="py-4 container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <h5 class="mb-4">Event Info</h5>
                        <div class="row">
                            <div class="text-center col-xl-5 col-lg-6">
                                <img
                                    v-if="event.imageV || event.imageH"
                                    v-img
                                    :alt="event.name"
                                    :src="event.imageV || event.imageH"
                                    class="mx-auto shadow-lg w-100 border-radius-lg"
                                />
                                <div class="pt-2 mt-4 my-gallery d-flex">
                                    <figure itemprop="associatedMedia" itemscope="" itemtype="http://schema.org/ImageObject">
                                        <img
                                            v-if="event.imageV"
                                            v-img="{
                                                group: 'v-img-gallery'
                                            }"
                                            :alt="event.name"
                                            :src="event.imageV"
                                            class="shadow w-75 min-height-100 max-height-100 border-radius-lg"
                                        />
                                    </figure>
                                    <figure itemprop="associatedMedia" itemscope="" itemtype="http://schema.org/ImageObject">
                                        <img
                                            v-if="event.imageH"
                                            v-img="{
                                                group: 'v-img-gallery'
                                            }"
                                            :alt="event.name"
                                            :src="event.imageH"
                                            class="shadow w-75 min-height-100 max-height-100 border-radius-lg"
                                        />
                                    </figure>
                                </div>

                                <!-- <div class="pswp" tabindex="-1" role="dialog" aria-hidden="true">
                                    <div class="pswp__bg"></div>
                                    <div class="pswp__scroll-wrap">
                                        <div class="pswp__container">
                                            <div class="pswp__item"></div>
                                            <div class="pswp__item"></div>
                                            <div class="pswp__item"></div>
                                        </div>
                                        <div class="pswp__ui pswp__ui--hidden">
                                            <div class="pswp__top-bar">
                                                <div class="pswp__counter"></div>
                                                <button class="me-2 btn btn-white btn-sm pswp__button pswp__button--close"> Close (Esc) </button>
                                                <button class="me-2 btn btn-white btn-sm pswp__button pswp__button--fs"> Fullscreen </button>
                                                <button class="me-2 btn btn-white btn-sm pswp__button pswp__button--arrow--left"> Prev </button>
                                                <button class="btn btn-white btn-sm pswp__button pswp__button--arrow--right"> Next </button>
                                                <div class="pswp__preloader">
                                                    <div class="pswp__preloader__icn">
                                                        <div class="pswp__preloader__cut">
                                                            <div class="pswp__preloader__donut"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="pswp__share-modal pswp__share-modal--hidden pswp__single-tap">
                                                <div class="pswp__share-tooltip"></div>
                                            </div>
                                            <div class="pswp__caption">
                                                <div class="pswp__caption__center"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div> -->

                            </div>
                            <div class="col-lg-6">
                                <h3 class="mt-4 mt-lg-0">{{ event.name }}</h3>
                                <!-- <div class="rating">
                                    <i class="fas fa-star" aria-hidden="true"></i>
                                    <i class="fas fa-star" aria-hidden="true"></i>
                                    <i class="fas fa-star" aria-hidden="true"></i>
                                    <i class="fas fa-star" aria-hidden="true"></i>
                                    <i class="fas fa-star-half-alt" aria-hidden="true"></i>
                                </div> -->
                                <!-- <br>
                                <h6 class="mt-3 mb-0">Price</h6>
                                <h5>$1,419</h5>
                                <span class="badge badge-success">In Stock</span>
                                <br> -->
                                <label class="mt-4">Description</label>
                                <div v-html="event.description"></div>

                                <!-- <ul>
                                    <li> The most beautiful curves of this swivel stool adds an elegant touch to any environment </li>
                                    <li>Memory swivel seat returns to original seat position</li>
                                    <li> Comfortable integrated layered chair seat cushion design </li>
                                    <li>Fully assembled! No assembly required</li>
                                </ul> -->

                                <!-- <div class="mt-4 row">
                                    <div class="mt-2 col-lg-5 mt-lg-0">
                                        <label>Frame Material</label>
                                        <div class="choices" data-type="select-one" tabindex="0" role="listbox" aria-haspopup="true" aria-expanded="false">
                                            <div class="choices__inner">
                                                <select id="choices-material" class="form-control choices__input" name="choices-material" hidden="" tabindex="-1" data-choice="active">
                                                    <option value="Choice 1">Wood</option>
                                                </select>
                                                <div class="choices__list choices__list--single">
                                                    <div class="choices__item choices__item--selectable" data-item="" data-id="1" data-value="Choice 1" data-custom-properties="null" aria-selected="true">Wood</div>
                                                </div>
                                            </div>
                                            <div class="choices__list choices__list--dropdown" aria-expanded="false">
                                                <div class="choices__list" role="listbox">
                                                    <div id="choices--choices-material-item-choice-1" class="choices__item choices__item--choice choices__item--selectable is-highlighted" role="option" data-choice="" data-id="1" data-value="Choice 3" data-select-text="" data-choice-selectable="" aria-selected="true">Aluminum</div>
                                                    <div id="choices--choices-material-item-choice-2" class="choices__item choices__item--choice choices__item--selectable" role="option" data-choice="" data-id="2" data-value="Choice 4" data-select-text="" data-choice-selectable="">Carbon</div>
                                                    <div id="choices--choices-material-item-choice-3" class="choices__item choices__item--choice choices__item--selectable" role="option" data-choice="" data-id="3" data-value="Choice 2" data-select-text="" data-choice-selectable="">Steel</div>
                                                    <div id="choices--choices-material-item-choice-4" class="choices__item choices__item--choice is-selected choices__item--selectable" role="option" data-choice="" data-id="4" data-value="Choice 1" data-select-text="" data-choice-selectable="">Wood</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="mt-2 col-lg-5 mt-lg-0">
                                        <label>Color</label>
                                        <div class="choices" data-type="select-one" tabindex="0" role="listbox" aria-haspopup="true" aria-expanded="false">
                                            <div class="choices__inner">
                                                <select id="choices-colors" class="form-control choices__input" name="choices-colors" hidden="" tabindex="-1" data-choice="active">
                                                    <option value="Choice 1">White</option>
                                                </select>
                                                <div class="choices__list choices__list--single">
                                                    <div class="choices__item choices__item--selectable" data-item="" data-id="1" data-value="Choice 1" data-custom-properties="null" aria-selected="true">White</div>
                                                </div>
                                            </div>
                                            <div class="choices__list choices__list--dropdown" aria-expanded="false">
                                                <div class="choices__list" role="listbox">
                                                    <div id="choices--choices-colors-item-choice-1" class="choices__item choices__item--choice choices__item--selectable is-highlighted" role="option" data-choice="" data-id="1" data-value="Choice 3" data-select-text="" data-choice-selectable="" aria-selected="true">Black</div>
                                                    <div id="choices--choices-colors-item-choice-2" class="choices__item choices__item--choice choices__item--selectable" role="option" data-choice="" data-id="2" data-value="Choice 4" data-select-text="" data-choice-selectable="">Blue</div>
                                                    <div id="choices--choices-colors-item-choice-3" class="choices__item choices__item--choice choices__item--selectable" role="option" data-choice="" data-id="3" data-value="Choice 2" data-select-text="" data-choice-selectable="">Gray</div>
                                                    <div id="choices--choices-colors-item-choice-4" class="choices__item choices__item--choice choices__item--selectable" role="option" data-choice="" data-id="4" data-value="Choice 6" data-select-text="" data-choice-selectable="">Pink</div>
                                                    <div id="choices--choices-colors-item-choice-5" class="choices__item choices__item--choice choices__item--selectable" role="option" data-choice="" data-id="5" data-value="Choice 5" data-select-text="" data-choice-selectable="">Red</div>
                                                    <div id="choices--choices-colors-item-choice-6" class="choices__item choices__item--choice is-selected choices__item--selectable" role="option" data-choice="" data-id="6" data-value="Choice 1" data-select-text="" data-choice-selectable="">White</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-2">
                                        <label>Quantity</label>
                                        <div class="choices" data-type="select-one" tabindex="0" role="listbox" aria-haspopup="true" aria-expanded="false">
                                            <div class="choices__inner">
                                                <select id="choices-quantity" class="form-control choices__input" name="choices-quantity" hidden="" tabindex="-1" data-choice="active">
                                                    <option value="Choice 1">1</option>
                                                </select>
                                                <div class="choices__list choices__list--single">
                                                    <div class="choices__item choices__item--selectable" data-item="" data-id="1" data-value="Choice 1" data-custom-properties="null" aria-selected="true">1</div>
                                                </div>
                                            </div>
                                            <div class="choices__list choices__list--dropdown" aria-expanded="false">
                                                <div class="choices__list" role="listbox">
                                                    <div id="choices--choices-quantity-item-choice-1" class="choices__item choices__item--choice is-selected choices__item--selectable is-highlighted" role="option" data-choice="" data-id="1" data-value="Choice 1" data-select-text="" data-choice-selectable="" aria-selected="true">1</div>
                                                    <div id="choices--choices-quantity-item-choice-2" class="choices__item choices__item--choice choices__item--selectable" role="option" data-choice="" data-id="2" data-value="Choice 2" data-select-text="" data-choice-selectable="">2</div>
                                                    <div id="choices--choices-quantity-item-choice-3" class="choices__item choices__item--choice choices__item--selectable" role="option" data-choice="" data-id="3" data-value="Choice 3" data-select-text="" data-choice-selectable="">3</div>
                                                    <div id="choices--choices-quantity-item-choice-4" class="choices__item choices__item--choice choices__item--selectable" role="option" data-choice="" data-id="4" data-value="Choice 4" data-select-text="" data-choice-selectable="">4</div>
                                                    <div id="choices--choices-quantity-item-choice-5" class="choices__item choices__item--choice choices__item--selectable" role="option" data-choice="" data-id="5" data-value="Choice 5" data-select-text="" data-choice-selectable="">5</div>
                                                    <div id="choices--choices-quantity-item-choice-6" class="choices__item choices__item--choice choices__item--selectable" role="option" data-choice="" data-id="6" data-value="Choice 6" data-select-text="" data-choice-selectable="">6</div>
                                                    <div id="choices--choices-quantity-item-choice-7" class="choices__item choices__item--choice choices__item--selectable" role="option" data-choice="" data-id="7" data-value="Choice 7" data-select-text="" data-choice-selectable="">7</div>
                                                    <div id="choices--choices-quantity-item-choice-8" class="choices__item choices__item--choice choices__item--selectable" role="option" data-choice="" data-id="8" data-value="Choice 8" data-select-text="" data-choice-selectable="">8</div>
                                                    <div id="choices--choices-quantity-item-choice-9" class="choices__item choices__item--choice choices__item--selectable" role="option" data-choice="" data-id="9" data-value="Choice 9" data-select-text="" data-choice-selectable="">9</div>
                                                    <div id="choices--choices-quantity-item-choice-10" class="choices__item choices__item--choice choices__item--selectable" role="option" data-choice="" data-id="10" data-value="Choice 10" data-select-text="" data-choice-selectable="">10</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="mt-4 row">
                                    <div class="col-lg-5">
                                        <button class="mb-0 btn bg-gradient-success mt-lg-auto w-100" type="button" name="button"> Add to cart </button>
                                    </div>
                                </div> -->
                            </div>
                        </div>

                        <EventTickets
                            :eventId="id"
                        />

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import EventTickets from '@/components/EventTickets.vue'

export default {
    name: 'EventInfo',

    props: ['id'],

    data() {
        return {}
    },

    components: {
        EventTickets,
    },

    computed: {
        ...mapGetters('operations', [
            'event'
        ]),
    },

    beforeMount () {
        this.getEventInfoAction(this.id)
    },

    methods: {
        ...mapActions('operations', [
            'getEventInfoAction'
        ])
    },

}
</script>
